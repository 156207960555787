import { getAnalytics, logEvent } from "firebase/analytics";
import { isProd } from "./app-common";

export const ga_logEvent = (
  eventName: string,
  eventParams?: { [key: string]: any },
) => {
  if (isProd) {
    const analytics = getAnalytics();
    logEvent(analytics, eventName.toLocaleLowerCase(), eventParams);
  }
};
