import { IxParams } from "@/shared/typings";
import {
  SearchSuggestions,
  SuggestionsCategories,
  SuggestionsData,
} from "@/types/marketplace.interface";
import * as CryptoJS from "crypto-js";
import dayjs from "dayjs";

export const isS3Image = (url: string) => {
  return url?.includes("pumpsoul.com") ?? false; //) || (url?.includes('res-back-test.pumpsoul.com') ?? false);
  // https://pumpsoul-test.s3.amazonaws.com/aigc_image/ed08a1d895954d0e8411dceba452f8a4.jpg__op__resize,m_mfit,w_500,h_500,q_80__op__format,f_webp
};

export function imgUrl(v: string, type?: "small" | "mid" | "big") {
  const imgType = type ?? "mid";
  switch (imgType) {
    case "small":
      return imgix(v, { w: 200, h: 200 });
    case "big":
      return imgix(v, { w: 1000, h: 1000 });
    default:
      return imgix(v, { w: 500, h: 500 });
  }
}

export function imgix(v: string, params?: IxParams) {
  if (!v || !isS3Image(v)) return v || "";

  const w = params?.w ?? 100;
  const h = params?.h ?? 100;
  const q = params?.q ?? 80;
  return v + `__op__resize,m_mfit,w_${w},h_${h}__op__format,f_webp`;
}

export const isMobileByUserAgent = (userAgent: string) => {
  return Boolean(
    userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i,
    ),
  );
};
export const capitalizeString = (text: string) => {
  return (text || "")
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};

type transformType =
  | "pico"
  | "icon"
  | "thumb"
  | "small"
  | "compact"
  | "medium"
  | "large"
  | "grande"
  | "1024"
  | "2048";

/**
 * @link https://www.evernote.com/l/AT59JlZLi4xFW72l5E6dvv2Jtt8eHEOPbrY
 * @param url
 * @param size
 */
export function imageTransform(url: string, size: transformType | string) {
  if (typeof url !== "string") return url;

  const pietraS3SizeKeys = ["compact", "large", "grande", "1024"];
  const shopifySizeKeys = [
    "pico",
    "icon",
    "thumb",
    "small",
    "compact",
    "medium",
    "large",
    "grande",
    "1024",
    "2048",
  ];
  if (isS3Image(url)) {
    if (!pietraS3SizeKeys.includes(size)) return url;
    const regex = /(\/public.*?\/)/;
    return url.replace(regex, `/public_${size}/`);
  } else if (typeof size === "string") {
    size = size.toLowerCase();
    if (!shopifySizeKeys.includes(size)) return url;
    if (["1024", "2048"].includes(size)) {
      size = `${size}x${size}`;
    }
  } else if (typeof size === "number") {
    if (!(size > 0)) return url;
  } else {
    return url;
  }

  const pathArray = url.split("/");
  if (!/shopify\.com/.test(pathArray[2])) return url;

  const lastIndex = pathArray.length - 1;
  const tmpArray = pathArray[lastIndex].split(".");

  if (!/^(jpg|png|jpeg|webp)/i.test(tmpArray[1])) return url;

  const basename = tmpArray[0].replace(
    RegExp(`_(${shopifySizeKeys.join("|")}|\\d+x\\d+)$`, "ig"),
    "",
  );
  tmpArray[0] = basename + `_${size}`;
  pathArray[lastIndex] = tmpArray.join(".");
  return pathArray.join("/");
}

export const convertSuggestions = (
  data: SuggestionsData,
  subcategoryValueMap: any,
): SearchSuggestions => {
  const arr: SearchSuggestions = {
    stores: [],
    items: [],
    categories: [],
  };
  if (data.storeList) {
    arr.stores = data.storeList;
  }
  // TODO <editor-fold desc="Suspicious unused code">
  const allCategories: SuggestionsCategories[] = [];
  if (data.categories) {
    data.categories.forEach(function (item) {
      allCategories.push({
        text: subcategoryValueMap[item] || item,
        value: item,
        param: "category",
      });
    });
  }
  if (data.materials) {
    data.materials.forEach(function (item) {
      allCategories.push({
        text: capitalizeString(item),
        value: item,
        param: "material",
      });
    });
  }
  if (data.gemstones) {
    data.gemstones.forEach(function (item) {
      allCategories.push({
        text: capitalizeString(item),
        value: item,
        param: "gemstone",
      });
    });
  }
  arr.categories =
    allCategories.length > 4 ? allCategories.slice(0, 4) : allCategories;
  // </editor-fold>
  if (data.list) {
    arr.items = data.list.map((text) => ({ text }));
  }
  return arr;
};

export const shopAllLink = (title: string, params: any) => {
  if (params.exactRoute) {
    return params.exactRoute;
  }
  const urlPath = title ? new URLSearchParams({ ...params, title }) : "";
  return `/shop/all?${urlPath}`;
};

export const zeroPaddedNumber = (num: number): string => {
  return `${num}`.padStart(2, "0");
};

export const formatPrice = (price: string): string => {
  if (!price) return "";

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(parseFloat(price));
};

export const capitalizeFirstLetter = (str: string): string => {
  if (str.length == 0 || str == null || str == undefined) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function areEqualIgnoreCase(str1: string, str2: string): boolean {
  return str1.toLowerCase() === str2.toLowerCase();
}

export const getPathTitle = (str: string): string => {
  if (str == null || str == undefined || str.length == 0) {
    return "-";
  }
  let resStr = str
    .replace(/[^\w\s\-]+/g, "")
    .replaceAll(" ", "-")
    .toLowerCase();
  return resStr.length === 0 ? "-" : resStr;
};

export function calculateMD5(input?: string): string {
  if (input == null || input == undefined || input.length == 0) {
    return "";
  }
  const md5Hash = CryptoJS.MD5(input.toLocaleLowerCase());
  const md5Hex = md5Hash.toString(CryptoJS.enc.Hex);
  return md5Hex;
}

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export function isEmailValid(email?: string): boolean {
  if (email == null || email == undefined || email.length == 0) {
    return false;
  }
  return emailRegex.test(email);
}

export function changeDate(
  dateString: string,
  format: string,
  daysAgo?: number,
): string {
  var myDate = dayjs(dateString);
  if (daysAgo) {
    myDate = myDate.subtract(daysAgo, "day");
  }
  return myDate.format(format);
}

export const isBlogWeb = () => {
  const url = new URL(process.env.NEXT_APP_WEB_HOST);
  const test_url = new URL("https://blog-test.dolphinradar.com");
  const prod_url = new URL("https://blog.dolphinradar.com");
  return url.host === test_url.host || url.host === prod_url.host;
};

export function ls_saveItem(key: string, value: string) {
  if (typeof window !== "undefined") {
    localStorage.setItem(key, value);
  }
}

export function ls_getItem(key: string): string {
  if (typeof window !== "undefined") {
    return localStorage.getItem("key");
  } else {
    return undefined;
  }
}

export function ls_removeItem(key: string) {
  if (typeof window !== "undefined") {
    localStorage.removeItem(key);
  }
}

export function getRandomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min)) + min;
}

// 检测是否为instagram链接
export function isInstagramProfileURL(url: string) {
  const pattern = new RegExp(
    "^https?:\\/\\/(www\\.)?instagram\\.com\\/([a-zA-Z0-9._-]+)\\/?$",
    "i",
  );
  return pattern.test(url);
}

//提取instagram链接username
export function extractInstagramUsername(url: string) {
  const pattern = /https?:\/\/(www\.)?instagram\.com\/([a-zA-Z0-9._-]+)\/?/i;
  const match = url.match(pattern);
  return match ? match[2] : null;
}
