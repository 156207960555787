import React, { useEffect, useState } from "react";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import { Router, useRouter } from "next/router";
import NProgress from "nprogress";
import "@/styles/mixins.less";
import "@/styles/fonts.css";
import "@/styles/globals.less";
import "nprogress/nprogress.css";
import { App as AntdApp } from "antd";
import ConfigProvider, { useConfigProvider } from "@/context/ConfigProvider";
import ShoppingCartProvider from "@/context/UserProvider";
import withTheme from "@/theme";
import "@/lib/hooks/common/firebaseConfig/init";
import { appWithTranslation } from "next-i18next";
import { Locale } from "antd/lib/locale";
import { CurrentLanguage } from "@/shared/language";
// import Login from '@/components/Login';
import UserProvider from "@/context/UserProvider";
// import CookieConsentContent from '@/components/Common/CookieConsent';
import dynamic from "next/dynamic";
// import CustomHead from '@/components/Common/CustomHead';
import ErrorBoundary from "@/components/Layouts/ErrorBoundary";
import LoginResult from "@/components/LoginResult";
// import ArticleBottomAlert from '@/components/Common/ArticleBottomAlert';
import { AppSettings } from "@/shared/app-common";
Router.events.on("routeChangeStart", NProgress.start);
Router.events.on("routeChangeError", NProgress.done);
Router.events.on("routeChangeComplete", NProgress.done);

require("../styles/ins-theme.less");

const Login = dynamic(() => import("@/components/Login"), { ssr: false });
const CookieConsentContent = dynamic(
  () => import("@/components/Common/CookieConsent"),
  {
    ssr: false,
  },
);
const ArticleBottomAlert = dynamic(
  () => import("@/components/Common/ArticleBottomAlert"),
  {
    ssr: false,
  },
);

export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout<P = {}> = AppProps<P> & {
  Component: NextPageWithLayout<P>;
};
// const myPoppins = localFont({
//   src: "../../public/fonts/Poppins-Thin.otf",
//   display: "swap",
//   weight: "200",
//   style: "normal",
// });
// const myMontserrat = localFont({
//   src: "../../public/fonts/OpenSans-Regular.ttf",
//   display: "swap",
//   weight: "regular",
//   style: "normal",
// });

// const myMontserrat500 = localFont({
//   src: "../../public/fonts/OpenSans-Medium.ttf",
//   display: "swap",
//   weight: "500",
//   style: "normal",
// });

// const myMontserrat400 = localFont({
//   src: "../../public/fonts/OpenSans-Bold.ttf",
//   display: "swap",
//   weight: "400",
//   style: "normal",
// });

// const myCaveat = localFont({
//   src: "../../public/fonts/Caveat-Regular.ttf",
//   display: "swap",
//   weight: "regular",
//   style: "normal",
// });

function App({ Component, pageProps }: AppPropsWithLayout) {
  const [loc, setLoc] = useState<Locale>();
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();
  const { isShowArticleBottomAlert } = useConfigProvider();

  useEffect(() => {
    setLoc(CurrentLanguage(router.locale).locale);
  }, [router.locale]);

  return withTheme(
    <AntdApp>
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${AppSettings.clarityId}");`,
        }}
      />
      <ConfigProvider>
        <UserProvider>
          <ShoppingCartProvider>
            {/* <ErrorBoundary> */}

            {/* <CustomHead /> */}
            {getLayout(<Component {...pageProps} />)}
            <Login />
            <LoginResult />
            {/* <ArticleBottomAlert /> */}
            <CookieConsentContent />

            {/* </ErrorBoundary> */}
          </ShoppingCartProvider>
        </UserProvider>
      </ConfigProvider>
    </AntdApp>,
    loc,
  );
}

export default appWithTranslation(App);
