import { useTranslation } from "next-i18next";

export const isProd =
  process.env.NODE_ENV === "production" &&
  !process.env.NEXT_APP_API_HOST.includes("-test.");

// App 6
export const AppType = process.env.NEXT_APP_TENANTID;

export const isDrFans = "15" == AppType;

// name
const getAppName = () => {
  switch (AppType) {
    case "15":
      return "Copycobra";
    default:
      return "";
  }
};

export const lowerName = getAppName().replace(/ /g, "_").toLowerCase();

// app logo
const getAppLogo = `/images/layouts/${lowerName}-logo.png`;
// app net logo
const getAppNetLogo = `https://res-gpts.pumpsoul.com/logo/${lowerName}.webp`;
// app name
const getAppLogoName = `/images/layouts/${lowerName}-name.png`;
// app ico
const getAppICO = `/${lowerName}_favicon.ico`;

// const getWebHost = `https://www.dolphinradar.com`;
// const getBlogHost = `https://www.dolphinradar.com`;

export const currentHost = () => {
  if (typeof window === "undefined") {
    return "";
  }

  if (window.location.host.includes("localhost")) {
    return `localhost:3000`;
  } else if (window.location.host.includes("-test")) {
    return `www-test.copycobra.pro`;
  } else {
    return `www.copycobra.pro`;
  }
};

const getWebHost = () => {
  if (typeof window === "undefined") {
    return "";
  }

  if (window.location.host.includes("-test.")) {
    return `https://www-test.copycobra.pro/`;
  } else {
    return `https://www.copycobra.pro/`;
  }

  // switch (process.env.NEXT_APP_ENV) {
  //   case 'test1':
  //     return `http://localhost:3000`;
  //   // case 'testProduction':
  //   //   return `https:\/\/www-test.dolphinradar.com`;
  //   default:
  //     return `https://www.dolphinradar.com`;
  // }
};

const getBlogHost = () => {
  if (typeof window === "undefined") {
    return "";
  }

  if (window.location.host.includes("-test.")) {
    return `https://www-test.copycobra.pro`;
  } else {
    return `https://www.copycobra.pro`;
  }

  // switch (process.env.NEXT_APP_ENV) {
  //   case 'test1':
  //     return `http://localhost:3000`;
  //   // case 'testProduction':
  //   //   return `https:\/\/blog-test.dolphinradar.com`;
  //   default:
  //     return `https://blog.dolphinradar.com`;
  // }
};

// app title
const getAppTitle = () => {
  switch (AppType) {
    case "15":
      return "goooooo";
    default:
      return "";
  }
};

// app desc
const getAppDesc = () => {
  switch (AppType) {
    case "15":
      return `Secure your digital assets with Copycobra's powerful anti-piracy tool. Our platform provides real-time monitoring, efficient DMCA takedown services, and comprehensive copyright protection. Safeguard your content with DMCA protected solutions.`;
    default:
      return ``;
  }
};

// app sub title
const getAppHeadSubTitle = () => {
  switch (AppType) {
    case "15":
      return `Copycobra | Anti-Piracy & DMCA Takedown Tool | Protect Your Content`;
    default:
      return "";
  }
};

//
const getFirebaseConfig = () => {
  if (isProd) {
    return {
      apiKey: "AIzaSyAnZtu4Qq_n2U1Ahd6Z7VusSTtomeikCQQ",
      // authDomain: "cobra-bb4e7.firebaseapp.com",
      authDomain: `${currentHost()}/fb-auth`,
      projectId: "cobra-bb4e7",
      storageBucket: "cobra-bb4e7.appspot.com",
      messagingSenderId: "1032141637306",
      appId: "1:1032141637306:web:546715db82290ded21e483",
      measurementId: "G-RB30G7FV4M",
    };
  } else {
    return {
      apiKey: "AIzaSyD2ww4PdEEmVTiKpwlWZ3gHF1wjEXGhrTU",
      // authDomain: 'www-test.dolphinradar.com-auth',
      authDomain: `${currentHost()}/fb-auth`,
      // authDomain: "cobra-test-3d945.firebaseapp.com",
      projectId: "cobra-test-3d945",
      storageBucket: "cobra-test-3d945.appspot.com",
      messagingSenderId: "950087465335",
      appId: "1:950087465335:web:4e0cc79f160e43e44a0972",
      measurementId: "G-1SZV6EGKP7",
    };
  }
};

interface AppSettings {
  type: number;
  name: string;
  logoUrl: string;
  logoPath: string;
  namePath: string;
  icoPath: string;
  title: string;
  headSubTitle: string;
  desc: string;
  firebaseConfig: {};
  navItems: [];
  webHost: string;
  blogHost: string;
  clarityId: string;
}

export const AppSettings = {
  type: AppType,
  name: getAppName(),
  logoUrl: getAppNetLogo,
  logoPath: getAppLogo,
  namePath: getAppLogoName,
  icoPath: getAppICO,
  title: getAppTitle(),
  headSubTitle: getAppHeadSubTitle(),
  desc: getAppDesc(),
  firebaseConfig: getFirebaseConfig(),
  webHost: getWebHost(),
  blogHost: getBlogHost(),
  clarityId: isProd ? `nd67w6jfn7` : "=",
};
